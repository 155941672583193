import { onMounted, ref } from "vue";

export const useInView = (selector) => {
  const inView = ref(false);
  onMounted(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };
    let observer = new IntersectionObserver(callback, options);

    function callback(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && inView.value === false) {
          inView.value = true;
        }
      });
    }

    let target = document.querySelector(selector);

    if (target) {
      observer.observe(target);
    }
  });

  return inView;
};
