<template>
  <div class="overflow-hidden">
    <SiteNav />
    <div class="">
      <section class="hero-section relative pt-8 pb-16 lg:mb-0 lg:pt-0">
        <div class="relative">
          <div class="hero-section__img mb-8 lg:mx-0 lg:mb-0">
            <img src="./assets/hero.png" />
          </div>
          <div class="l-container">
            <div class="hero-section__side">
              <div
                class="text-center max-w-md mx-auto lg:mx-0 lg:max-w-lg lg:text-left"
              >
                <p class="tagline">Eazipay for Individuals</p>
                <h1 class="heading level-1 mb-4">
                  Pay all in <span>one click!</span>
                </h1>
                <p class="text-summary mb-6 lg:w-4/5">
                  Add your service providers, personal assistants, chauffeurs,
                  chefs, and more to your Eazipay. Pay all in one click!
                </p>
                <div class="hero__cta">
                  <SiteButton color="secondary" shadow to="/login">
                    Start Using Free, Forever
                  </SiteButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="loans-section py-12 lg:py-20">
        <div class="l-container">
          <div
            class="flex flex-col-reverse lg:grid grid-cols-2 lg:gap-16 items-center"
          >
            <div>
              <img src="./assets/loans.png" />
            </div>
            <div
              class="text-center max-w-md mx-auto mb-10 lg:mb-0 lg:max-w-none lg:text-left"
            >
              <p class="tagline">Loans</p>
              <h2 class="heading level-2 mb-4">
                Loans, <span>whenever you need them</span>
              </h2>
              <p class="text-summary">
                Whenever you fall short of your monthly payroll budget, you may
                easily request a loan to cover for it.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        class="bills-and-payments-section relative py-20"
        id="billSectionContainer"
      >
        <div class="absolute right-0 top-0">
          <img src="./assets/red-dots.png" alt="" />
        </div>
        <div class="l-container">
          <div class="max-w-lg mx-auto text-center mb-10">
            <p class="tagline">bills and payments</p>
            <h2 class="heading level-2 mb-4">
              Fast Transfer, Bills and Payments
            </h2>
            <p class="text-summary">
              Pay all relevant bills, send to anyone and save your loved ones as
              beneficiaries.
            </p>
          </div>

          <div class="bills-items hidden lg:block" id="billSection">
            <div :hidden="billSectionInView === false">
              <div class="bills-items-row -a mb-8">
                <div v-for="item of items[0]" :key="item.label" class="item">
                  <div class="item-icon">
                    <img :src="item.icon" />
                  </div>
                  <div class="item-label">{{ item.label }}</div>
                </div>
                <div class="items-row-break" />
              </div>
              <div class="bills-items-row -b">
                <div v-for="item of items[1]" :key="item.label" class="item">
                  <div class="item-icon">
                    <img :src="item.icon" />
                  </div>
                  <div class="item-label">{{ item.label }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap justify-center gap-x-4 gap-y-4 lg:hidden">
            <div v-for="item of allItems" :key="item.label" class="item">
              <div class="item-icon">
                <img :src="item.icon" />
              </div>
              <div class="item-label">{{ item.label }}</div>
            </div>
          </div>

          <div class="mt-8 lg:mt-12 text-center">
            <SiteButton color="secondary" shadow class="lg:w-64" to="/login"
              >Get started now</SiteButton
            >
          </div>
        </div>
      </section>
    </div>
    <SiteFooter />
  </div>
</template>

<script setup>
import SiteFooter from "../../components/SiteFooter.vue";
import SiteNav from "../../components/SiteNav.vue";

import airtime from "./assets/airtime.png";
import data from "./assets/data.png";
import electricity from "./assets/electricity.png";
import tv from "./assets/tv.png";
import transfer from "./assets/transfer.png";
import salary from "./assets/salary.png";
import loan from "./assets/loan.png";
import SiteButton from "../../components/SiteButton.vue";
import { useInView } from "../../utils/useInView";

const billSectionInView = useInView("#billSection");

const items = [
  [
    {
      icon: airtime,
      label: "Airtime",
    },
    {
      icon: data,
      label: "Data",
    },
    {
      icon: electricity,
      label: "Electricity",
    },
  ],
  [
    {
      icon: tv,
      label: "Gotv/Dstv",
    },
    {
      icon: transfer,
      label: "Make a Transfer",
    },
    {
      icon: salary,
      label: "Pay Salaries",
    },
    {
      icon: loan,
      label: "Get Loan",
    },
  ],
];

const allItems = items.flat();
</script>

<style lang="scss" scoped>
@import url(../../styles/www.scss);

[hidden] {
  visibility: hidden;
}
.hero-section {
  background: linear-gradient(
    180deg,
    #ffe8e6 -67.96%,
    rgba(245, 213, 210, 0) 100%
  );

  h1 {
    span {
      color: #ea4e4b;
    }
  }
  .l-container {
    width: 100%;
  }

  &__img {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-width: 100%;
    }
  }
}

.loans-section {
  .heading {
    span {
      color: #ea4e4b;
    }
  }
}

.bills-and-payments-section {
  background-image: url("./assets/patterns.png");
  background-color: #ffeeec;
  background-position: center;
  background-size: cover;

  .bills-items-row {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .item {
    padding: 20px;
    width: max(30%, 180px);
    background-color: white;
    box-shadow: 0px 9px 18px rgba(170, 170, 170, 0.15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .item-icon {
      background: #f2f1f1;
      border-radius: 12px;
      width: 60px;
      height: 60px;
      display: grid;
      place-items: center;
      margin-bottom: 14px;
      img {
        width: 25px;
      }
    }

    .item-label {
      font-size: 14px;
    }
  }
}

@screen lg {
  .hero-section {
    overflow: hidden;

    &__side {
      padding: 210px 0;
    }

    &__img {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 58%;
      max-width: none;

      img {
        max-width: none;
        max-height: 100%;
      }
    }
  }

  .bills-and-payments-section {
    .item {
      min-width: 250px;
      width: auto;
      padding: 20px 40px;

      .item-label {
        font-size: 16px;
      }
    }

    .bills-items-row {
      &.-a {
        animation: slideInFromRight 1s cubic-bezier(0, 0.24, 0.59, 1.23);
      }

      &.-b {
        animation: slideInFromLeft 1s cubic-bezier(0, 0.24, 0.59, 1.23);
      }
    }
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(90%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-90%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
